import React from 'react'
import { useInView } from 'react-intersection-observer'

// Components
import AnimateSlideIn from '../animation/AnimateSlideIn'
import AnimateSplitText from '../animation/AnimateSplitText'
import Button from '../Button'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import Spacer from '../Spacer'

// Styles
import { animation } from '../../styles/vars/animation.style'
import { colors } from '../../styles/vars/colors.style'
import { Heading1 } from '../../styles/vars/textStyles.style'
import { Hero, HeadingContainer } from './index.style'
import { TextBodySmaller } from '../TextStyles'

// SVGs
import IconArrowheadRight from '../svgs/IconArrowheadRight'

const ErrorHero = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: animation.blockThreshold,
  })
  return (
    <Hero>
      <Container>
        <Grid>
          <GridItem
            mobileL={11}
            tabletP={7}
            tabletL={8}
            tabletLStart={3}
            desk={6}
            deskStart={3}
            deskL={4}
            deskLStart={3}
          >
            <HeadingContainer ref={ref} inView={inView}>
              <AnimateSplitText delay={1.2}>
                <Heading1 as={`h1`} color={colors.green}>
                  Page not found
                </Heading1>
              </AnimateSplitText>
              <AnimateSlideIn delay={1.4}>
                <Button
                  iconRight={<IconArrowheadRight responsive={false} />}
                  color={colors.black}
                  href={`/`}
                >
                  <TextBodySmaller>Home Page</TextBodySmaller>
                </Button>
              </AnimateSlideIn>
            </HeadingContainer>
            <Spacer size={[0, 20]} />
          </GridItem>
        </Grid>
      </Container>
    </Hero>
  )
}

export default ErrorHero
