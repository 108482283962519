import React from 'react'
import Seo from '../components/Seo'
import Spacer from '../components/Spacer'
import ErrorHero from '../components/ErrorHero'

const NotFoundPage = () => {
  return (
    <>
      <Seo title="404" description="Page not found" />{' '}
      <Spacer size={[182, 400]} />
      <ErrorHero />
      <Spacer size={40} />
    </>
  )
}

export default NotFoundPage
