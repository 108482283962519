import styled from 'styled-components'

export const Hero = styled.div`
  position: relative;
`
export const HeadingContainer = styled.div`
  position: relative;

  a {
    padding-left: 0.5rem;
  }
`
